<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-btn
      slot="activator"
      color="primary"
      dark
    >
      New Fee
    </v-btn>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New Fee</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="fee.title"
                  label="Title"
                  :rules="titleRules"
                  :counter="20"
                  required
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="fee.amount"
                  label="Amount Required"
                  hint="how much the fee needs to be paid off"
                  :rules="amountRules"
                  :counter="10"
                  required
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="fee.deductMethod"
                  :items="deductItems"
                  item-text="text"
                  item-value="value"
                  label="Deduction Method"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-fade-transition>
                  <v-text-field
                    v-if="fee.deductMethod === 'Schedule'"
                    v-model="fee.schedule"
                    label="Deduction Schedule"
                    hint="on which day month or year should the dedction occur"
                    :counter="10"
                    required
                  />
                </v-fade-transition>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-textarea
                  v-model="fee.description"
                  label="Brief Description of the Fee"
                  :rules="descRules"
                  :counter="200"
                  box
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click.native="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="submit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'FeesNewDialogButton',
  data() {
    return {
      dialog: false,
      valid: true,
      fee: {},
      titleRules: [
        v => !!v || 'Title is reqired',
        v => (v && v.length <= 20) || 'Title must be less than 20 characters',
      ],
      amountRules: [
        v => !!v || 'Amount is reqired',
        v => (/^\d*$/.test(v)) || 'Only numbers are accepted',
        v => (v && v.length <= 10) || 'Amount must be less than 10  characters',
      ],
      descRules: [
        v => (v && v.length <= 200) || 'Description must be less than 200 characters',
      ],
      deductItems: [
        { text: 'Course + Timed', value: 'course' },
        { text: 'Timed', value: 'timed' },
      ],
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('fees/create', this.fee)
          .then((data) => {
            this.clear()
            this.dialog = false
            EventBus.$emit('newFeeCreated', data)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
