<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs10>
            <h2>{{ heading }}</h2>
            <span class="subheading">
              {{ subheading }}
            </span>
          </v-flex>
          <v-flex
            xs2
            text-xs-right
          >
            <fees-new-button />
          </v-flex>
        </v-layout>
        <v-divider />
      </v-flex>
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm12
            md12
          >
            <fees-list />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import FeesNewDialogButton from '@/components/fees/FeesNewDialogButton.vue'
import FeesList from '@/components/fees/FeesList.vue'

export default {
  name: 'Fees',
  components: {
    'fees-new-button': FeesNewDialogButton,
    'fees-list': FeesList,
  },
  data() {
    return {

    }
  },
  computed: {
    heading() {
      return 'Fees'
    },
    subheading() {
      return ''
    },
  },
}
</script>
