<template>
  <div v-if="items">
    <v-icon
      large
      @click="listView = !listView"
    >
      {{ listView ? 'view_module' : 'list' }}
    </v-icon>
    <v-data-iterator
      :items="items"
      :rows-per-page-items="rowsPerPageItems"
      :pagination.sync="pagination"
      content-tag="v-layout"
      row
      wrap
      expand
    >
      <v-flex
        slot="item"
        slot-scope="props"
        xs12
        :sm6="!listView"
        :md4="!listView"
        :lg3="!listView"
      >
        <v-card>
          <v-card-title>
            <h4>{{ props.item.title }}</h4>
          <!-- <v-btn icon small >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn icon small>
            <v-icon>close</v-icon>
          </v-btn> -->
          </v-card-title>
          <v-divider />
          <v-list dense>
            <v-list-tile>
              <v-list-tile-content>Pay Amount:</v-list-tile-content>
              <v-list-tile-content class="align-end">
                {{ `$ ${ props.item.amount }` }}
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>Method:</v-list-tile-content>
              <v-list-tile-content class="align-end">
                {{ printFeeMethod(props.item.deductMethod) }}
              </v-list-tile-content>
            </v-list-tile>
            <!-- <v-list-tile>
            <v-list-tile-content>Schedule:</v-list-tile-content>
            <v-list-tile-content class="align-end">
              {{ props.item.schedule || 'N/A' }}
            </v-list-tile-content>
          </v-list-tile> -->
            <v-list-tile>
              <v-list-tile-content>Description:</v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content class="align-start">
                {{ props.item.description }}
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
    </v-data-iterator>
  </div>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'FeesList',
  data() {
    return {
      listView: false,
      rowsPerPageItems: [4, 8, 12],
      items: null,
      pagination: {
        rowsPerPage: 8,
      },
    }
  },
  created() {
    this.$store.dispatch('fees/all')
      .then((data) => { this.items = data })
    EventBus.$on('newFeeCreated', fee => this.items.push(fee))
  },
  beforeDestroy() {
    EventBus.$off('newFeeCreated')
  },
  methods: {
    printFeeMethod(method) {
      switch (method) {
        case 'timed': return 'Timed'
        case 'course': return 'Course + Timed'
        default: return 'Unknown'
      }
    },
  },
}
</script>
